import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Header, { LogoLink } from "../headers/light.js";
import { scrollTo } from "helpers/scrollTo.js";
import { navDetails } from "helpers/navLinks.js";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;

const Container = tw.div`relative -mx-8 -mt-8`;
const TwoColumn = tw.div`flex flex-col lg:flex-row bg-gray-100`;
const LeftColumn = tw.div`ml-8 mr-8 xl:pl-10 py-8`;
const RightColumn = styled.div`
  background-image: url("https://images.unsplash.com/photo-1603891345603-7163d3ee559e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80");
  ${tw`
  bg-gray-900
  bg-cover
  bg-center
  xl:ml-24
  h-96
  lg:h-auto
  lg:w-1/2
  lg:flex-1
  `}
`;

const Content = tw.div`mt-24 lg:mt-24 lg:mb-24 flex flex-col sm:items-center lg:items-stretch`;
const Heading = tw.h1`text-3xl sm:text-5xl md:text-6xl lg:text-5xl font-black leading-none text-gray-800`;
const Paragraph = tw.p`max-w-md my-8 lg:my-5 lg:my-8 sm:text-lg lg:text-base xl:text-lg leading-loose text-gray-600`;

const Actions = styled.div`
  ${tw`mb-8 lg:mb-0`}
  .action {
    ${tw`
    text-center inline-block w-full sm:w-48 py-4 font-semibold tracking-wide rounded
    hocus:outline-none focus:shadow-outline transition duration-300
    hover:cursor-pointer
    `}
  }
  .primaryAction {
    ${tw`border-2 border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-gray-100`}
  }
`;

const activeYears = new Date().getFullYear() - 1998;
export default ({
  navLinks = navDetails,
  heading = (
    <>
      Engineers and Consultants
      <wbr />
      <br />
      <span tw="text-primary-500">all across Nigeria</span>
    </>
  ),
  description = `We've been doing this for ${activeYears} years. We provide you the very best Mechanical & Electrical building services.`,
  primaryActionUrl = "locations",
  primaryActionText = "Get in touch!",
}) => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
          <Content>
            <Heading>{heading}</Heading>
            <Paragraph>{description}</Paragraph>
            <Actions>
              <p onClick={() => scrollTo(primaryActionUrl)} className="action primaryAction">
                {primaryActionText}
              </p>
            </Actions>
          </Content>
        </LeftColumn>

        <RightColumn></RightColumn>
      </TwoColumn>
    </Container>
  );
};
