import tw from "twin.macro";
import { HashLink as Link } from 'react-router-hash-link';

import { NavLinks, NavLink as NavLinkBase } from "components/headers/light.js";

const NavLink = tw(NavLinkBase)`
  sm:text-sm sm:mx-6
`;

export const navDetails = [
  <NavLinks key={1}>
    <NavLink>
      <Link smooth to="/#about">
        About us
      </Link>
    </NavLink>
    <NavLink href="/projects">Projects</NavLink>
    <NavLink>
      <Link smooth to="/#locations">
        Locate us
      </Link>
    </NavLink>
  </NavLinks>,
];
