import React from "react";

import MainBanner from "components/hero/FullWidthWithImage";
import TrackRecord from "components/features/TwoColSingleFeatureWithStats2.js";
import Expertise from "components/features/TwoColWithSteps";
import Locations from "components/cards/ThreeColContactDetails";
import Brief from "components/cta/GetStartedLight"

export default () => (
  <>
    <MainBanner />
    <TrackRecord />
    <Expertise />
    <Locations />
    <Brief />
  </>
);
