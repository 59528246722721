import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = styled.div`
${tw`
relative
h-full
`}

`;
const Content = tw.div`w-9/12 md:w-auto mx-auto py-10 flex flex-col lg:flex-row gap-4`;

const HeadingTitle = tw(SectionHeading)`text-2xl text-center leading-tight`;
const Description = tw.p`max-w-md text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;

const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");
  min-height: 240px;
  max-height: 240px;
  `,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded bg-gray-800`
]);


export default ({
  image,
  heading = "Summary",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {

  return (
    <Container>
      <Content>
        <CardImage imageSrc={image} />
        <HeadingInfo tw="lg:block" heading={heading} description={description} />
      </Content>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    <HeadingTitle>{heading}</HeadingTitle>
    <Description>{description}</Description>
  </div>
);
