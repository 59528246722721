import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto my-20 md:my-24 items-center`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 flex-shrink-0 relative`;
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const ImageHolder = styled.div`
${tw`
grid
grid-cols-3
grid-flow-row
items-center
justify-items-center
gap-4
`}

img {
  max-width: 85%
}
`;

const DecoratorBlob = styled(SvgDotPattern)(() => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;

const Steps = tw.ul`mt-12`;
const Step = tw.li`mt-8 flex flex-col md:flex-row items-center md:items-start`;
const StepNumber = tw.div`font-semibold text-4xl leading-none text-gray-400`;
const StepText = tw.div`mt-3 md:mt-0 md:ml-6`;
const StepHeading = tw.h6`leading-none text-xl font-semibold text-gray-700`;
const StepDescription = tw.p`mt-3 max-w-xs leading-loose text-sm text-gray-600 font-medium`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Projects delivered by <span tw="text-primary-500">Professionals.</span>
    </>
  ),
  imageDecoratorBlob = true,
  textOnLeft = true,
  steps = null,
  decoratorBlobCss = null,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  function importAll(r) {
    return r.keys().map((item) => ({
      file: r(item),
      fileName: item.replace("./", ""), // remove file start
      label: item
        .replace("./", "")
        .replace(/\.(png|jpe?g)$/, "") // remove file extension
        .replace(/_[aA-zZ]+/, ""), // remove underscore and subsequent letters
    }));
  }

  const thumbs = importAll(
    require.context('../../images/logos', false, /\.(png|jpe?g)$/)
  );
  const logos = thumbs.map((image) => image.file.default)

  const defaultSteps = [
    {
      heading: "Engineering",
      description: "We provide a team of dedicated, certified and experienced Engineers."
    },
    {
      heading: "Consulting",
      description: "Pre-design feasibility studies, Condition survey, Infrastructure design and more."
    },
    {
      heading: "Project Management",
      description: "Monitoring and Evaluation of projects, Preparation and Evaluation of BEM/tender reports, Preparation of status reports and more"
    }
  ];

  if (!steps) steps = defaultSteps;

  return (
    <Container id="about">
      <TwoColumn>
        <ImageColumn>
          <ImageHolder>
            {logos.map((logo, index) => (
              <img key={index} alt="logo" src={logo}/>
            ))}
          </ImageHolder>
          {imageDecoratorBlob && <DecoratorBlob css={decoratorBlobCss} />}
        </ImageColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Subheading>{subheading}</Subheading>
            <Heading>{heading}</Heading>
            <Steps>
              {steps.map((step, index) => (
                <Step key={index}>
                  <StepNumber>{(index+1).toString().padStart(2,'0')}</StepNumber>
                  <StepText>
                    <StepHeading>{step.heading}</StepHeading>
                    <StepDescription>{step.description}</StepDescription>
                  </StepText>
                </Step>
              ))}
            </Steps>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
