import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";

import firs from "images/projects/4.jpeg";
import cbn from "images/projects/1.png";
import cbn1 from "images/projects/2.png";

import Temp from "components/testimonials/TwoColumnWithImageAndProfilePictureReview"

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto my-16 lg:my-20`;

const Heading = tw(SectionHeading)`mb-10`;

const Card = styled.div`
min-height: 495px;
max-height: 495px;

${tw`
h-full flex! flex-col
shadow-md
max-w-sm rounded-2xl sm:rounded-4xl sm:rounded-2xl sm:rounded-4xl
`}
`;

const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");
  min-height: 240px;
  max-height: 240px;
  `,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded bg-gray-800 rounded-t-2xl sm:rounded-t-4xl rounded-b-none`
]);

const TextInfo = styled.div`
${tw`
p-6
`}
`;

const Title = tw.h5`text-xl font-bold text-center text-gray-800`;

const Description = styled.p`
${tw`
text-sm leading-loose mt-2 sm:mt-4 text-gray-600 text-center
`}
display: -webkit-box;

-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
`;

const PrimaryButton = tw(PrimaryButtonBase)`
mt-auto sm:text-lg rounded-none w-full rounded rounded-b-2xl sm:rounded-b-4xl py-3 sm:py-6 rounded-t-none
border-2 border-primary-500
hover:bg-white hover:text-primary-500
bg-primary-500 text-gray-100
`;

const Modal = styled.div`
  ${tw`
    justify-center
    items-center
    flex
    overflow-x-hidden
    overflow-y-auto
    fixed
    inset-0
    z-50
    outline-none
    focus:outline-none
  `}


`;

const ModalContainer = tw.div`
relative w-auto my-6 mx-auto max-w-3xl
`;

const ModalContent =  tw.div`
border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none
`;

const ModalBody = tw.div`
relative p-6 flex-auto
`;

const ModalOuter = tw.div`
opacity-25 fixed inset-0 z-40 bg-black
`;

const CardHolder = styled.div`
${tw`
flex
flex-wrap
items-center
justify-center
gap-12
`}

`;

export default () => {
  const cards = [
    {
      imageSrc: firs,
      title: "FIRS - Sokoto MSTO Consultancy",
      description: "Consulting on mechanical services for the upgrading and extension of the Federal Inland Revenue Service Sokoto MSTO.",
      active: false,
    },

    {
      imageSrc: cbn,
      title: "CBN - Renovation Consultancy, Abeokuta Branch",
      description: "Mechanical & Electrical Consultancy for the completion of outstanding renovation works at the CBN Abeokuta branch.",
      active: false,
    },

    {
      imageSrc: cbn1,
      title: "CBN - Nigerian Police Force - National CyberCrime Centre",
      description: "Mechanical & Electrical Consultancy for the Nigerian Police Force - National CyberCrime Centre (NPF-NCCC)."
    }

    // {
    //   imageSrc: "https://picc.vercel.app/api/images/30",
    //   title: "Silerbird Cinema Complex - M&E Consultancy",
    //   description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    //   active: false,
    // },
    // {
    //   imageSrc: "https://picc.vercel.app/api/images/30",
    //   title: "University of Illorin - M&E Consultancy",
    //   description: "M&E Consultancy for the construction of the Abuja liason office.",
    //   active: false,
    // },
    // {
    //   imageSrc: "https://picc.vercel.app/api/images/30",
    //   title: "University of Abuja - M&E Consultancy",
    //   description: "Mechanical engineering consultancy for the proposed institute of education & Electrical engineering consultancy for the proposed faculty of law.",
    //   active: false,
    // },
    // {
    //   imageSrc: "https://picc.vercel.app/api/images/30",
    //   title: "CAC - Zonal office consultancy",
    //   description: "Consulting on mechanical and electrical servies for the offices in Ado-Ekiti, Benin, Jalingo, Jos, Owerri, Kano, Maidugiri, Markurdi, Sokoto, Bauchi, Enugu, Kaduna, Lagos and Portharcourt",
    //   active: false,
    // }
  ];

  const [showModal, setShowModal] = useState(false);
  const [cardControl, setCardControl] = useState(cards);

  const openModal = (index) => {
    setShowModal(true);
    const control = cardControl;
    control[index].active = true

    setCardControl(control)
  };

  const closeModal = (index) => {
    setShowModal(false);
    const control = cardControl;
    control[index].active = false;

    setCardControl(control)
  };

  const modalRef = useRef(null);

  const useOutsideContainer = (ref) => {
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (
          ref.current && // ref is not null
          !ref.current.contains(e.target) // is click outside
        ) {
          closeModal(ref.current.id);
        }
      };
      window.addEventListener("mousedown", handleClickOutside);

      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideContainer(modalRef);

  return (
    <Container>
      <Content>
        <Heading>Notable Projects</Heading>

        <CardHolder>
          {cardControl.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />

              <TextInfo>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </TextInfo>

              {(card.active && showModal) ? (
                <>
                  <Modal>
                    <ModalContainer id={index} ref={modalRef}>
                      <ModalContent>
                        <ModalBody>
                          <p style={{color: 'red', textAlign: 'right', cursor: 'pointer', fontWeight: 700}} onClick={() => closeModal(index)}>X</p>
                          <Temp description={card.description} image={card.imageSrc} />
                        </ModalBody>
                      </ModalContent>
                    </ModalContainer>
                  </Modal>

                  <ModalOuter className="opacity-25 fixed inset-0 z-40 bg-black"></ModalOuter>
                </>
              ) : null}

              <PrimaryButton onClick={() => openModal(index)}>More Details</PrimaryButton>
            </Card>
          ))}
        </CardHolder>
      </Content>
    </Container>
  );
};
