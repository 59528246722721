import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  SectionHeading,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { NavLink as NavLinkBase } from "../headers/light.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import {Icon} from 'leaflet'



const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`
  flex flex-col items-center
  md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto my-20 md:my-24`}
`;

const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-500`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const MapColumn = styled.a`
  ${tw`
  w-full
  max-w-xl
  h-48 md:h-auto
  shadow-2xl
  `}

  .leaflet-container {
    width: 100%;
    height: 100%;
  }
`;

const Card = styled.div`
  ${tw`
  flex flex-col sm:flex-row
  items-center sm:items-start
  text-center sm:text-left
  h-full
  mx-4
  px-2
  py-8
  `}

  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-gray-700 text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-500 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`
  pointer-events-none
  absolute
  right-0
  bottom-0
  w-64
  opacity-25
  transform
  translate-x-32 translate-y-48
  `}
`;

const NavLink = tw(NavLinkBase)`
  sm:text-lg mx-2 text-primary-500
`;

export default ({
  cards = null,
  subheading = "Location",
  description = "You can reach us",
}) => {
  const defaultCards = [
    {
      title: "Head Office",
      description:
        `Suite 2F, Second Floor
Adisa Plaza
No,39 David Ejoor Crescent
Gudu District
Abuja FCT
`,
    },
  ];

  if (!cards) cards = defaultCards;

  const position = [9.00385799823938, 7.471738668434343];

  return (
    <Container id="locations">
      <ThreeColumnContainer>
        <Heading>{subheading}</Heading>

        {description && (
          <Description>
            {description}
            <NavLink
              href="https://goo.gl/maps/x49mP43cjCWMKme99"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              here
            </NavLink>
            or via
            <NavLink
              href="mailto:enedengineering@gmail.com"
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Email
            </NavLink>
          </Description>
        )}

        <VerticalSpacer />

        <MapColumn
          href="https://goo.gl/maps/x49mP43cjCWMKme99"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={position}
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                })
              }
            >
              <Popup />
            </Marker>
          </MapContainer>
        </MapColumn>

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p
                  className="description"
                  style={{
                    whiteSpace: "pre",
                  }}
                >
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
