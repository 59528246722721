import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "components/footers/MiniCenteredFooter";

import Landing from "pages/Landing";
import Project from "pages/Project";
import AnimationRevealPage from "helpers/AnimationRevealPage";

export default function App() {
  return (
    <Router>
      <div style={{ overflowX: "hidden" }}>
        <AnimationRevealPage disabled>
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>

            <Route exact path="/projects">
              <Project />
            </Route>
          </Switch>
        </AnimationRevealPage>
        <Footer />
      </div>
    </Router>
  );
}
