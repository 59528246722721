import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import MainBanner from "components/cards/ThreeColSlider";

import Header, { LogoLink } from "components/headers/light.js";
import { navDetails } from "helpers/navLinks";

const StyledHeader = styled(Header)`
  ${tw`justify-between`}
  ${LogoLink} {
    ${tw`mr-8 pb-0`}
  }
`;


export default ({ navLinks= navDetails }) => {return (
  <>
   <StyledHeader links={navLinks} collapseBreakpointClass="sm" />
    <MainBanner />
  </>
)};
